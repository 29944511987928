import { useEvent } from '@prodelio/event-system/hooks/useEvent';
import { GetTasksByProjectRequest } from '@prodelio/modules/tasks/features/list-tasks-by-project/requests/GetTasksByProject.request';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectEvents } from '@prodelio/modules/projects/Project.events';
import {
  TaskView,
  TaskViewSection,
} from '@prodelio/components/task-view/TaskView';
import { useEffect } from 'react';
import { useTaskStore } from '@prodelio/modules/tasks/state/useTaskStore';
import {
  TaskSortOptions,
  useSelectTasksV2,
} from '@prodelio/modules/tasks/state/selectors/selectTasks';
import { GetBoardsRequest } from '@prodelio/modules/tasks/features/list-tasks-by-project/GetBoardsRequest';
import { Board } from '@prodelio/modules/projects/state/Board';
import { EditBoard } from '@prodelio/modules/projects/features/edit-board/EditBoard';
import { DeleteBoard } from '@prodelio/modules/projects/features/delete-board/DeleteBoard';
import { useBoardStore } from '@prodelio/modules/projects/state/useBoardStore';
import { getBoards } from '@prodelio/modules/projects/state/queries/getBoards';
import { Event } from '@prodelio/modules/common/models/Event';

export const ListTasksByProject = () => {
  const { projectId } = useParams<string>();
  const navigate = useNavigate();

  const getBoardRequest = new GetBoardsRequest(projectId ?? '');
  const fetchBoards = useBoardStore((store) => store.fetchBoards);
  const boards = getBoards({
    projectId,
  });

  const request = new GetTasksByProjectRequest(projectId || '');

  const filters = {
    projectId,
    parentTaskId: null,
  };

  useEvent(
    [ProjectEvents.DELETED_PROJECT, ProjectEvents.ARCHIVED_PROJECT],
    (event: Event) => {
      if (!event.data) {
        return;
      }

      const { id } = event.data;

      if (projectId === id) {
        navigate('/app/inbox');
      }
    }
  );

  const syncTasks = useTaskStore((state) => state.syncTasks);

  useEffect(() => {
    syncTasks(request, filters);
    fetchBoards(getBoardRequest, { projectId });
  }, [projectId]);

  const sort = [TaskSortOptions.MANUAL];

  const tasks = useSelectTasksV2(filters, sort);

  const sections: TaskViewSection[] = boards.map((board: Board) => {
    return {
      key: board._id.toString(),
      tasks: tasks.filter((task) => task.boardId?.equals(board._id)),
      header: board.title,
      filters: { ...filters, boardId: board._id },
      sort,
      displayHeader: true,
      actions: [<EditBoard board={board} />, <DeleteBoard board={board} />],
    };
  });

  sections.unshift({
    key: 'ListTasksByProject' + projectId,
    header: 'General',
    tasks: tasks.filter((task) => !task.boardId),
    filters,
    sort,
    displayHeader: true,
  });

  return (
    <TaskView
      canChange
      taskFilters={filters}
      sections={sections}
      viewId={projectId}
    />
  );
};
